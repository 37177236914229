import React from 'react';
import PropTypes from 'prop-types';

const CodeBlock = ({ fileContent, coverage }) => {
  const lines = fileContent.split('\n');

  return (
    <div className="code-container">
      {lines.map((line, index) => {
        const match = coverage.find(i => i.lineNumber === index + 1);
        if (match) {
          if (match.coveredInstructions === 0) {
            return (
              <pre key={index} className="code-line uncovered-line">
                <span className="line-number">{index + 1}</span> {line}
              </pre>
            );
          } else if (match.coveredInstructions > 0 && match.missedInstructions === 0) {
            return (
              <pre key={index} className="code-line covered-line">
                <span className="line-number">{index + 1}</span> {line}
              </pre>
            );
          }
          return (
            <pre key={index} className="code-line partially-covered-line">
              <span className="line-number">{index + 1}</span> {line}
            </pre>
          );
        } else {
          return (
            <pre key={index} className="code-line">
              <span className="line-number">{index + 1}</span> {line}
            </pre>
          );
        }
      })}
    </div>
  );
};

CodeBlock.propTypes = {
  fileContent: PropTypes.string.isRequired,
  coverage: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      lineNumber: PropTypes.number.isRequired,
      missedInstructions: PropTypes.number.isRequired,
      coveredInstructions: PropTypes.number.isRequired,
      missedBranches: PropTypes.number.isRequired,
      coveredBranches: PropTypes.number.isRequired
    })
  ).isRequired
};

export default CodeBlock;
