import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const FileUploadComponent = uploadToken => {
  const { id } = useParams();

  const [files, setFiles] = useState([]);
  // const [uploadToken, setUploadToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobId, setJobId] = useState(null);
  const jobIdRef = useRef(jobId);

  const [jobStatus, setJobStatus] = useState(null);

  const handleFileChange = event => {
    setFiles(event.target.files);
  };

  // const handleTokenChange = event => {
  //   setUploadToken(event.target.value);
  // };

  useEffect(() => {
    const checkStatus = async () => {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/jobs/projects/${id}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) return;

      const data = await response.json();

      setJobId(data.id);
      jobIdRef.current = data.id;
      checkJobStatus();
    };

    checkStatus();
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('token', uploadToken.uploadToken);
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    try {
      const url = process.env.REACT_APP_SERVER_URL + '/api/file-upload/coverage';
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: formData
      });

      if (!response.ok) {
        setIsSubmitting(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const jobId = await response.text();
      setJobId(jobId);
      jobIdRef.current = jobId;
      setIsSubmitting(false);

      checkJobStatus();
    } catch (error) {
      console.error('Error uploading file', error);
    }
  };

  const checkJobStatus = () => {
    const intervalId = setInterval(() => {
      if (!jobIdRef.current) return;
      const url = `${process.env.REACT_APP_SERVER_URL}/api/jobs/${jobIdRef.current}`;
      fetch(url, {
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          const status = data.status;
          setJobStatus(status);
          if (status == 'COMPLETE' || status == 'ERROR') clearInterval(intervalId);
        });
    }, 2000);

    return () => clearInterval(intervalId);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {jobStatus && <div>Status: {jobStatus}</div>}
        <Form.Group className="mb-3">
          <Form.Label>Upload your jacoco.xml file (target/site/jacoco)</Form.Label>
          <Form.Control type="file" multiple onChange={handleFileChange}></Form.Control>
        </Form.Group>
        <Row className="justify-content-center mx-0">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner animation="border" role="status" variant="primary" />
                {'Uploading...'}
              </>
            ) : (
              <>Upload Files</>
            )}
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default FileUploadComponent;
