import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectList = ({ project }) => {
  let navigate = useNavigate();

  function navigateToProject(id) {
    // JS is necessary to make the entire row clickable
    navigate('/project/' + id);
  }

  return (
    <>
      <tr className="project" onClick={() => navigateToProject(project.id)}>
        <td>
          <h3>{project.repositoryName}</h3>
        </td>
        <td>
          <h3>{(project.instructionCoverage * 100).toFixed(2)}%</h3>
        </td>
      </tr>
    </>
  );
};

ProjectList.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    repositoryName: PropTypes.string.isRequired,
    instructionCoverage: PropTypes.number.isRequired
  }),
  index: PropTypes.number
};

export default ProjectList;
