import React, { useEffect, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import ProjectList from './ProjectList';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);

      const url = process.env.REACT_APP_SERVER_URL + '/api/projects/owner';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setProjects(data);
      } else {
        setError(response.statusText);
      }

      setLoading(false);
    };

    fetchProjects();
  }, []);

  return (
    <>
      {loading && <Spinner animation="border" role="status" variant="primary"></Spinner>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <>
          {/* Using a table helps the button position match the project list */}
          <Table borderless style={{ margin: 0 }}>
            <tbody>
              <tr>
                <td align="right">
                  <Button href="filtered-repositories" variant="primary">
                    Add project
                  </Button>
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          {projects.length > 0 ? (
            <Table striped borderless hover>
              <tbody>
                {projects.map((project, index) => (
                  <ProjectList project={project} key={project.id} index={index} />
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="p-x1 mb-0 bg-body-tertiary">You have no projects</p>
          )}
        </>
      )}
    </>
  );
};

export default Projects;
