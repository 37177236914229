import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

const PrivateRoutes = () => {
  const [validSession, setIsValidSession] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionChecked && !validSession) {
      // This is a shortcut to help prevent a flash before the page moves away.
      // The <Navigate> tag below is also necessary, because this will not catch all cases.
      navigate('/authentication/simple/login');
    }

    const verifySession = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/api/authentication/validate-session', {
          method: 'GET',
          credentials: 'include'
        });

        if (!response.ok) throw Error('Invalid session');
        setIsValidSession(true);
      } catch (error) {
        // The try-catch ensures we don't accidentally return true
        // for reasons like a failed call to the endpoint
        setIsValidSession(false);
      } finally {
        setSessionChecked(true);
      }
    };

    verifySession();
  }, []);

  if (!sessionChecked) return;

  return sessionChecked && validSession ? <Outlet /> : <Navigate to={'/authentication/simple/login'} />;
};

export default PrivateRoutes;
