import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import CodeBlock from './CodeBlock';

const ProjectFile = () => {
  const { provider, owner, repo, '*': path } = useParams();
  const { state } = useLocation();

  const [fileContent, setFileContent] = useState(null);
  const [filename, setFilename] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectFile = async () => {
      setLoading(true);
      try {
        const url =
          process.env.REACT_APP_SERVER_URL + `/api/${provider}/repositories/file/${owner}/${repo}?path=${path}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`Error fetching project file: ${response.statusText}`);
        }

        const data = await response.json();
        const content = atob(data.content);

        setFilename(data.name);
        setFileContent(content);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectFile();
  }, [provider, owner, repo, path]);

  return (
    <>
      {loading && <Spinner animation="border" role="status" variant="primary"></Spinner>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <Card>
          <Card.Header style={{ justifyContent: 'between', alignItems: 'center' }} className="bg-body-tertiary">
            <h3 className="mb-0">{filename}</h3>
          </Card.Header>
          <Card.Body className="fs--1">
            <Row>
              <Col md={12} className="h-100">
                <CodeBlock fileContent={fileContent} coverage={state.sourcefile.lines} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ProjectFile;
