import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import defaultAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';

const ProfileDropdown = ({ provider }) => {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const url = process.env.REACT_APP_SERVER_URL + `/api/${provider}/user`;
        const response = await fetch(url, {
          method: 'GET',
          header: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`Error fetching user: ${response.statusText}`);
        }

        const data = await response.json();
        setAvatarUrl(data.avatarUrl);
      } catch (err) {
        setError(err.message);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [provider]);

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" as="button" className="pe-0 ps-2 nav-link">
        {(loading || error) && <Avatar src={defaultAvatar} />}
        {!loading && !error && <Avatar src={avatarUrl} />}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={process.env.REACT_APP_SERVER_URL + '/logout'}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  provider: PropTypes.string.isRequired
};

export default ProfileDropdown;
