import React, { useState, useEffect } from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const FilteredRepositories = () => {
  const [repositories, setRepositories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const url = process.env.REACT_APP_SERVER_URL + '/api/GITHUB/repositories/filtered';
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setRepositories(data);
      } catch (error) {
        setError(error.message);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async repository => {
    const data = {
      repositoryProvider: repository.repositoryProvider,
      id: repository.id
    };

    try {
      const url = process.env.REACT_APP_SERVER_URL + '/api/projects';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const project = await response.json();
      navigate('/project/' + project.id);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      {loading && <Spinner animation="border" role="status" variant="primary"></Spinner>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <Table striped borderless hover>
          <tbody>
            {repositories.map(repository => (
              <tr key={repository.id}>
                <td>
                  <h3>{repository.name}</h3>
                </td>
                <td>
                  <Button variant="outline-primary" onClick={() => handleSubmit(repository)}>
                    Add to Bluecov
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default FilteredRepositories;
