import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import SocialAuthButtons from './SocialAuthButtons';

const LoginForm = () => (
  <Form>
    <SocialAuthButtons />
  </Form>
);

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
