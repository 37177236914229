export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: []
};

export const pagesRoutes = {
  label: 'pages',
  children: [
    {
      name: 'Projects',
      icon: ['far', 'file-code'],
      to: '/',
      active: true
    }
  ]
};

export default [dashboardRoutes, pagesRoutes];
