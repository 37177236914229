import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

const CoverageHistory = id => {
  console.log(id);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);

  useEffect(() => {
    const fetchHistory = async ({ id }) => {
      const url = process.env.REACT_APP_SERVER_URL + `/api/coverage-history/${id}`;
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`Error fetching project history: ${response.statusText}`);
      }

      const history = await response.json();

      const userLocale = navigator.language || navigator.userLanguage;
      const xData = history.map(c =>
        new Date(c.createdAt * 1000).toLocaleDateString(userLocale, { month: 'short', day: 'numeric', year: 'numeric' })
      );
      setXAxisData(xData);

      const yData = history.map(c => (c.instructionCoverage * 100).toFixed(2));
      setYAxisData(yData);
    };

    fetchHistory(id);
  }, []);

  const tooltipFormatter = params => {
    return `
    <div>
        <h6 class="fs-10 text-700 mb-0">
        <span class="dot me-1 d-inline-block" style="background-color:${
          params[0].borderColor ? params[0].borderColor : params[0].color
        }"></span>
          ${params[0].name} : ${params[0].value}%
        </h6>
    </div>
    `;
  };

  const getOption = () => ({
    color: [getColor('success'), getColor('primary'), getColor('info'), getColor('warning')],
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: getColor('gray-1100'),
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      axisTick: { show: false },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('gray-200')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: false
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0,
      max: 100
    },
    series: [
      {
        type: 'line',
        data: yAxisData,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 7,
        smooth: true,
        emphasis: {
          scale: true
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.5)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: { right: '0%', left: '0%', bottom: '10%', top: '0%' }
  });

  return <ReactEChartsCore echarts={echarts} option={getOption()} style={{ height: '10rem' }} />;
};

export default CoverageHistory;
