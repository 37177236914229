import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FileUploadComponent from './FileUpload';
import CoverageHistory from './CoverageHistory';

const Project = () => {
  const { id } = useParams();

  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  function navigateToProject(sourcefile) {
    navigate(
      `/project-files/${projectData.repositoryProvider}` +
        `/${projectData.ownerUsername}/` +
        `${projectData.repositoryName}/` +
        `${sourcefile.repositoryProviderPath}`,
      { state: { sourcefile: sourcefile } }
    );
  }

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        // Fetch project data
        const url = process.env.REACT_APP_SERVER_URL + `/api/projects/${id}`;
        const projectResponse = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!projectResponse.ok) {
          throw new Error(`Error fetching project data: ${projectResponse.statusText}`);
        }

        const projectData = await projectResponse.json();
        setProjectData(projectData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  return (
    <>
      {loading && <Spinner animation="border" role="status" variant="primary"></Spinner>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <>
          <CoverageHistory id={id} />
          <Row>
            <Col md={8}>
              <Card>
                <Card.Header>
                  <Row>
                    <Col md={9}>
                      <h2>{projectData.repositoryName}</h2>
                    </Col>
                    <Col md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <h3>{(projectData.instructionCoverage * 100).toFixed(2)}%</h3>
                    </Col>
                  </Row>
                </Card.Header>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Header style={{ justifyContent: 'between', alignItems: 'center' }} className="bg-body-tertiary">
                  File Upload
                </Card.Header>
                <Card.Body>
                  <FileUploadComponent uploadToken={projectData.uploadToken} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {projectData.reports.length === 0 ? (
            <p>No files found</p>
          ) : (
            <Table striped borderless hover>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th>Instructions Covered</th>
                  <th>Instructions Missed</th>
                  <th>Coverage</th>
                </tr>
              </thead>
              <tbody>
                {projectData.reports.map(report =>
                  report.packages.map(pack =>
                    pack.sourcefiles.map(sourcefile => (
                      <tr key={sourcefile.id} className="project" onClick={() => navigateToProject(sourcefile)}>
                        <td>
                          <Link to="#">{sourcefile.name}</Link>
                        </td>
                        {sourcefile.counters.find(c => c.type == 'INSTRUCTION') ? (
                          <>
                            <td>{sourcefile.counters.find(c => c.type === 'INSTRUCTION').covered}</td>
                            <td>{sourcefile.counters.find(c => c.type === 'INSTRUCTION').missed}</td>
                            <td>
                              {(
                                (sourcefile.counters.find(c => c.type === 'INSTRUCTION').covered /
                                  (sourcefile.counters.find(c => c.type === 'INSTRUCTION').covered +
                                    sourcefile.counters.find(c => c.type === 'INSTRUCTION').missed)) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                          </>
                        ) : (
                          // Keep full-row highlighting in case of no coverage data
                          <>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </>
                        )}
                      </tr>
                    ))
                  )
                )}
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  );
};

export default Project;
