import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const SocialAuthButtons = () => {
  return (
    <>
      <Form.Group>
        <Link to={process.env.REACT_APP_SERVER_URL + '/oauth2/authorization/auth0'}>
          <Button variant="" className="btn-outline-github mt-1 w-100">
            <FontAwesomeIcon icon={['fab', 'github-square']} transform="grow-8" className="me-2" /> GitHub
          </Button>
        </Link>
      </Form.Group>
    </>
  );
};

export default SocialAuthButtons;
